(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("canvas"));
	else if(typeof define === 'function' && define.amd)
		define(["canvas"], factory);
	else if(typeof exports === 'object')
		exports["identicon"] = factory(require("canvas"));
	else
		root["identicon"] = factory(root["canvas"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__160__) {
return 